import React, { useState, useLayoutEffect, useMemo } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Pagination } from '../Blocks'
import { PAGE_LIMIT_NEWS } from '@monorepo-amais/commons/utils/enums'
// import { theme } from 'styled-tools'
import { formataDataCosmic } from '@monorepo-amais/commons/utils/date'
import imgixUrlOptimizerAuto from '@monorepo-amais/commons/utils/imgixUrlOptimizerAuto'

const CardContainer = styled.div`
	display: flex;
	border: 1px solid ${props => props.theme.colors.graye2};
	border-radius: 3px;
	margin-bottom: 40px;
	:not(:nth-child(2)) {
		margin-top: 40px;
	}
	@media (max-width: 767.98px) {
		flex-wrap: wrap;
		margin-bottom: 20px;
		:not(:first-child) {
			margin-top: 20px;
		}
	}
	@media (min-width: 768px) and (max-width: 991.98px) {
		flex-wrap: wrap;
	}
	@media (min-width: 992px) and (max-width: 1199.98px) {
		flex-wrap: wrap;
	}
`
const ImgCard = styled.img`
	height: 300px;
	padding: 0;
	width: 300px;
	object-fit: scale-down;

	@media (max-width: 1199.98px) {
		width: 100%;
	}
`
const NoticiasContainer = styled.div`
	padding: 15px 15px 0 15px;
	width: 100%;
`
const TextoContainer = styled.div`
	height: 230px;
	@media (max-width: 767.98px) {
		height: unset;
	}
	@media (min-width: 992px) and (max-width: 1199.98px) {
		height: unset;
	}
`
const Title = styled.h3`
	margin: 0;
	position: relative;
	font-size: 26px;
	font-weight: bold;
	line-height: 1.32;
	text-align: left;
	color: ${props => props.theme.colors.black_fonts};
	&:after {
		content: '';
		position: absolute;
		bottom: -10px;
		left: 0;
		width: 90px;
		height: 5px;
		background: ${props => props.theme.colors.primary};
		@media (max-width: 767.98px) {
			font-size: 22px;
		}
	}
	@media (max-width: 767.98px) {
		font-size: 30px;
	}
	@media (min-width: 992px) and (max-width: 1199.98px) {
		font-size: 36px;
	}
`
const Text = styled.p`
	font-size: 16px;
	line-height: 1.19;
	text-align: left;
	color: ${props => props.theme.colors.gray66};
	margin-top: 25px;
	@media (max-width: 767.98px) {
		font-size: 16px;
	}
	@media (min-width: 768px) and (max-width: 991.98px) {
		font-size: 22px;
	}
	@media (min-width: 992px) and (max-width: 1199.98px) {
		font-size: 26px;
	}
`
const PData = styled.p`
	width: 50%;
	font-size: 18px;
	font-weight: bold;
	line-height: 1.17;
	text-align: left;
	color: #707070;
	margin: 0;
	padding-left: 10px;
	@media (max-width: 767.98px) {
		font-size: 15px;
	}
	@media (min-width: 992px) and (max-width: 1199.98px) {
		font-size: 22px;
	}
`
const Btn = styled(Link)`
	width: 50%;
	font-size: 20px;
	font-weight: bold;
	line-height: 1.3;
	text-align: right;
	color: ${props => props.theme.colors.primary};
	margin: 0;
	text-decoration: none;
	@media (max-width: 767.98px) {
		font-size: 15px;
	}
	@media (min-width: 992px) and (max-width: 1199.98px) {
		font-size: 22px;
	}
`
const Hr = styled.hr`
	height: 1px;
	border: none;
	background-color: ${props => props.theme.colors.graye2};
	&:first-child {
		display: none;
	}
`
const RodapeCard = styled.div`
	display: flex;
	align-items: center;
	margin-top: 10px;
	padding: 0 0 5px;
	@media (max-width: 767.98px) and (max-width: 1199.98px) {
		padding: 0;
		margin-bottom: 10px;
	}
`

const Icon = styled.img``

const CardsNoticias = ({ cosmicData, filters, Calendar, linkPrefix, linkText }) => {
	// ordena noticias por data decrescente
	const news = useMemo(
		() =>
			cosmicData.nodes.sort((prev, next) => {
				const dataPrev = new Date(`${prev.metadata.data} 00:00`)
				const dataNext = new Date(`${next.metadata.data} 00:00`)
				return dataPrev > dataNext ? -1 : 0
			}),
		[cosmicData]
	)

	const [items, setItems] = useState(news.slice(0, PAGE_LIMIT_NEWS))
	let [page, setPage] = useState(1)
	let pages = news.length / PAGE_LIMIT_NEWS
	/**
	 * Foi usado o Math.ceil para que quando o resultado de pages for um número quebrado/decimal ele arredonde para cima e anule o botão para a próxima página.
	 **/
	let [totalPages, setTotalPages] = useState(Math.ceil(pages))

	// Assignments to the 'pages' variable from inside React Hook useLayoutEffect will be lost after each render. To preserve the value over time, store it in a useRef Hook and
	// keep the mutable value in the '.current' property. Otherwise, you can move this variable directly inside useLayoutEffect
	const pagesRef = React.useRef()
	useLayoutEffect(() => {
		setPage(1)
		const offset = 0
		let arrFilter = filters ? news.filter(item => item.metadata.data.includes(filters)) : news
		pagesRef.current = arrFilter.length / PAGE_LIMIT_NEWS
		setTotalPages(Math.ceil(pagesRef.current))
		setItems(arrFilter.slice(offset, offset + PAGE_LIMIT_NEWS))
	}, [news, filters])

	const handleChangePage = page => {
		const offset = (page - 1) * PAGE_LIMIT_NEWS
		let arrFilter = filters ? news.filter(item => item.metadata.data.includes(filters)) : news
		const newItems = arrFilter.slice(offset, offset + PAGE_LIMIT_NEWS)
		window.scrollTo({
			behavior: 'smooth',
			top: 0
		})
		setPage(page)
		setItems(newItems)
	}

	return (
		<>
			{items && (
				<Pagination
					elementToRender={item => (
						<>
							<Hr />
							<CardContainer>
								<ImgCard src={imgixUrlOptimizerAuto(item.metadata.thumbnail.imgix_url)} />
								<NoticiasContainer>
									<TextoContainer>
										<Title id={`title-list-item-news-card-${item.slug}`}>{item.title}</Title>
										<Text>{item.metadata.pequena_descricao}</Text>
									</TextoContainer>
									<Hr />
									<RodapeCard>
										<Icon src={Calendar}></Icon>
										<PData>{formataDataCosmic(item.metadata.data)}</PData>
										<Btn id={`button-read-news-${item.slug}`} to={`/${linkPrefix}/${item.slug}`}>
											{linkText}
										</Btn>
									</RodapeCard>
								</NoticiasContainer>
							</CardContainer>
						</>
					)}
					elementsPerPage={PAGE_LIMIT_NEWS}
					totalPages={totalPages}
					handleNextPage={handleChangePage}
					items={items}
					page={page}
					needFetch={true}
				/>
			)}
		</>
	)
}

CardsNoticias.defaultProps = {
	linkPrefix: 'noticias',
	linkText: 'Ler notícia completa'
}

export default CardsNoticias
