import React from 'react'
import styled from 'styled-components'

const SidebarDiv = styled.div`
	border-radius: 3px;
	border: 1px solid ${props => props.theme.colors.graye2};
	padding: 20px 10px;
`

const SidebarTitle = styled.p`
	margin: 0;
	font-size: 18px;
	color: #464646;
	font-weight: bold;
`

const SidebarList = styled.ul`
	padding: 0;
`

const SidebarItem = styled.li`
	list-style: none;
	border-radius: 3px;
	background-color: ${props => props.theme.colors.grayf9};
	display: flex;
	justify-content: space-between;
	padding: 10px 20px 10px 15px;
	align-items: center;
	margin-bottom: 5px;
	cursor: pointer;
	transition: 0.3s;
`

const Qty = styled.p`
	color: ${({ filterStyle, theme }) => (filterStyle ? `${theme.colors.primary}` : '#77787b')};
	font-weight: ${({ filterStyle }) => (filterStyle ? 'bold' : 'normal')};
	font-size: 12px;
	margin: 0;
`

const Label = styled.p`
	font-weight: ${({ filterStyle }) => (filterStyle ? 'bold' : 'normal')};
	cursor: pointer;
	font-size: 14px;
	margin: 0;
`

const SidebarFiltro = ({ cosmicData, updateFilters, filterStyle }) => {
	const count = {}

	const years = cosmicData.nodes.reduce((arr, item) => {
		const year = item.metadata.data.substring(0, 4)
		if (!arr.includes(year)) {
			count[year] = 1
			return arr.concat([year])
		}
		count[year] += 1
		arr.sort(function(a, b) {
			return b - a
		})
		return arr
	}, [])

	return (
		<SidebarDiv>
			<SidebarTitle>Arquivo</SidebarTitle>
			<SidebarList>
				{years.map(item => (
					<SidebarItem key={item.id} name={item} onClick={updateFilters(item)} id={item}>
						<Label filterStyle={filterStyle === item} htmlFor={item}>
							{item}
						</Label>
						<Qty filterStyle={filterStyle === item}>( {count[item]} )</Qty>
					</SidebarItem>
				))}
			</SidebarList>
		</SidebarDiv>
	)
}

export default SidebarFiltro
