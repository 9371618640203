// const URL = 'https://search-grp-fry-pub-y7cql2malisjawhtcy5dep5e5u.us-west-2.es.amazonaws.com'
//const URL = 'https://search-search-sites-jcbmjbcrk4b2uyi5uv76revi2y.us-east-1.es.amazonaws.com'
// const URL = process.env.GATSBY_ELASTIC_URL
// export const URL_ELASTIC = path => `${URL}${path}`

export const PAGE_LIMIT_EXAM = 10
export const PAGE_LIMIT_UNIT = 12
export const PAGE_LIMIT_NEWS = 5

export const BUCKET_LIST = Object.freeze({
	mapas: 'Mapas',
	regies: 'Regiões',
	'servicos-diferenciados': 'Serviços diferenciados',
	horarios: 'Horários',
	'educacao-medica': 'Educação médica',
	'unidades-service': 'Unidades serviços',
	'home-cliente': 'Home cliente',
	'artigos-cientificos': 'Artigos científicos',
	institucional: 'Institucional',
	noticias: 'Notícias',
	'palavras-chave': 'Palavras-chave',
	'revistas-fleury': 'Revistas fleury',
	'manual-de-doencas2': 'Manual de doenças',
	'perguntas-frequentes': 'Perguntas frequentes',
	'revistas-medicas': 'Revistas médicas',
	'manual-de-doencas': 'Manual de doenças',
	'grupo-de-exames': 'Grupo de exames',
	facilidades: 'Facilidades',
	exames: 'Exames',
	diferenciais: 'Diferenciais',
	agendamentos: 'Agendamentos',
	'novos-servicos': 'Novos serviços',
	'unidades-apis': 'Unidades',
	'informacoes-estaticas': 'Informações estáticas',
	especialidades: 'especialidades',
	'unidades-guga': 'Unidades',
	'nossos-especialistas': 'Nossos especialistas',
	'home-medico': 'Home médico',
	'exames-services': 'Exames serviços',
	'receptivos-de-campanhas': 'Receptivos de campanhas',
	'roteiros-diagnosticos': 'Roteiros diagnósticos',
	'manual-de-exames': 'Manual de exames',
	'sobre-fleury-historia': 'Sobre fleury história',
	'algoritmos-diagnsticos': 'Algorítmos diagnósticos',
	'fale-conosco': 'Fale conosco',
	'manuais-diagnosticos': 'Manuais diagnósticos',
	unidades: 'Unidades',
	convenios: 'Convênios',
	revistas: 'Revistas',
	'boletins-medicos': 'Boletins Médicos',
	pages: 'Páginas',
	artigos: 'Artigos'
})
