import React, { useState } from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { BreadcrumbAuto } from '@monorepo-amais/commons/components/Blocks'
import SidebarFiltro from '@monorepo-amais/commons/components/Noticias/SidebarFiltro'
import CardsNoticias from '@monorepo-amais/commons/components/Noticias/CardsNoticias'
import Calendar from '@monorepo-amais/commons/assets/img/calendar.png'
import SEO from '../components/seo'
import { setClientMedic } from '@monorepo-amais/commons/utils/sessionControl'

const Container = styled.div`
	width: 85%;
	margin: 0 auto;

	@media (max-width: 767.98px) {
		width: 100%;
	}

	@media (min-width: 768px) and (max-width: 991.98px) {
		width: 95%;
	}

	@media (min-width: 992px) and (max-width: 1199.98px) {
		width: 95%;
	}
`

const Row = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 25px 0;
`

const ColLeft = styled.div`
	width: 73%;

	@media (max-width: 767.98px) {
		width: calc(100vw - 12px);
		margin: 0 6px;
	}
`

const ColRight = styled.div`
	width: 25%;

	@media (max-width: 767.98px) {
		display: none;
	}
`

const Title = styled.h1`
	margin: 0;
	position: relative;
	font-size: 40px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.32;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.black_fonts};
	&:after {
		content: '';
		position: absolute;
		bottom: -10px;
		left: 0;
		width: 90px;
		height: 5px;
		background: ${props => props.theme.colors.primary};

		@media (max-width: 767.98px) {
			height: 4px;
		}
	}

	@media (max-width: 767.98px) {
		font-size: 28px;
		margin: 0 12px 0 12px;
	}

	@media (min-width: 768px) and (max-width: 991.98px) {
		font-size: 40px;
	}

	@media (min-width: 992px) and (max-width: 1199.98px) {
		font-size: 50px;
	}
`

const Noticias = ({ data }) => {
	const [filters, setFilters] = useState('')

	setClientMedic('cliente')

	const updateFilters = e => () => {
		if (filters === e) {
			setFilters('')
		} else {
			setFilters(e)
		}
	}

	data.cosmicData = data.allCosmicjsNoticias

	return (
		<Layout footer={data.footer} regionais={data.regionais}>
			<SEO title='Notícias' />
			<Container>
				<BreadcrumbAuto title='Notícias' />

				<Title id='title-news-details'>Notícias</Title>
				<Row>
					<ColLeft>
						<CardsNoticias {...data} filters={filters} Calendar={Calendar} />
					</ColLeft>
					<ColRight>
						<SidebarFiltro {...data} updateFilters={updateFilters} filterStyle={filters} />
					</ColRight>
				</Row>
			</Container>
		</Layout>
	)
}

export default Noticias

export const query = graphql`
	query {
		allCosmicjsNoticias {
			nodes {
				id
				metadata {
					pequena_descricao
					seo {
						description
						image {
							imgix_url
							url
						}
						imageAlt
						title
					}
					thumbnail {
						imgix_url
						url
					}
					data
				}
				title
				slug
			}
		}
	}
`
