import moment from 'moment'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/esm/locale'

const isValidDate = date => {
	const dateFormat = 'DD-MM-YYYY'
	const day = date.substring(0, 2)
	const month = date.substring(3, 5)
	const year = date.substring(6, 10)
	const validateDate = `${month}/${day}/${year}`
	let validDate = false

	const currentDate = moment(validateDate)
	const now = moment()

	if (
		year.length === 4 &&
		currentDate <= now &&
		moment(moment(validateDate).format(dateFormat), dateFormat, true).isValid() &&
		parseInt(year) >= 1900
	) {
		validDate = true
	}

	return validDate
}

const getAge = birthDate => {
	const str = moment(birthDate, 'DD/MM/YYYY').fromNow()

	const age = parseInt(str.split(' ')[0])

	return age
}

const strToDate = str => {
	const date = new Date()
	date.setDate(parseInt(str.substring(0, 2)))
	date.setMonth(parseInt(str.substring(3, 5)) - 1)
	date.setFullYear(parseInt(str.substring(6, 10)))
	date.setHours(0)
	date.setMinutes(0)
	date.setSeconds(0)

	return date
}

const formatDateString = data => format(parseDate(data, 'yyyy/mm/dd'), 'dd/MM/yy', { locale: ptBR })

const nowString = () => moment().toISOString()

const parseDate = (input, format) => {
	format = format || 'yyyy-mm-dd' // default format
	var parts = input.match(/(\d+)/g),
		i = 0,
		fmt = {}
	// extract date-part indexes from the format
	format.replace(/(yyyy|dd|mm)/g, function(part) {
		fmt[part] = i++
	})

	return new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, parts[fmt['dd']])
}

/**
 * Formatar a data vinda do cosmic
 * @param data {string} data vinda do cosmic
 * @param noDay {boolean} caso true, não mostra o dia
 */
const formataDataCosmic = (data, noDay) => {
	let formatedDate = ''

	if (data) {
		formatedDate = format(parseDate(data, 'yyyy/mm/dd'), 'dd MMMM yyyy', { locale: ptBR })
		formatedDate =
			(noDay ? '' : `${formatedDate.substring(0, 2)} de `) +
			`${formatedDate.substring(3, formatedDate.length - 4)} de ${formatedDate.substring(
				formatedDate.length - 4,
				formatedDate.length
			)}`
	}

	return formatedDate
}

export { isValidDate, getAge, nowString, strToDate, formataDataCosmic, formatDateString }
